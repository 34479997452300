<template>
    <div>
       <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <AdminNavbar/>
        </div>
      </section>
        <div class="container mt-4">   
          <h4>Welcome to Admin Dashboard!</h4>        
        </div>   
      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container ">
          <div class="row" >
            <div class="col-lg-12 admin-main">
              <!-- <AdminHome v-if="ShowThis"/> -->
              <router-view></router-view>
            </div>       
            <!-- <div class="col-lg-4 admin">
              <div class="sidemenu">             
                <h3 class="title">Admin Menu Bar</h3>
                <div class="items tags">
                  <ul>
                    <li><router-link :to="{name:'admin-users'}">Manage Users</router-link></li>
                    <li><router-link :to="{name:'admin-awards'}">Manage Awards</router-link></li>
                    <li><router-link :to="{name:'admin-website'}">Manage Website</router-link></li>
                    <li><router-link :to="{name:'admin-movies'}">Manage Movies</router-link></li>   
                    <li><router-link :to="{name:'admin-profiles'}">User Profiles</router-link></li> 
                                    
                  </ul>
                </div>                
              </div>              
            </div> -->
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
export default {
  data () {
    return {
      ShowThis: true,
    }
  },
  watch: {
    '$route' ()
    {
      if(this.$route.name == "admin-dashboard") {
          this.ShowThis=true;
        }      
        else {
          this.ShowThis=false;
        }  
    }
  },   
  mounted()
  {
    this.ShowThis=true;
  },  
  methods: {
  },   
}
</script>

<style>
.admin-main {
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
    padding: 30px 50px 20px 20px;    
}

.admin-main .admin-text h5{
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
  margin-bottom:10px;
}
.blog .sidemenu {
    margin-top: 10px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.admin .sidemenu  .items {
    margin-bottom: 30px;
}
.admin .sidemenu .title  {
    font-size: 16px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #760001;
    position: relative;
}
.admin .sidemenu .tags {
    margin-bottom: -10px;
}

.admin .sidemenu .tags ul {
    list-style: none;
    padding: 0;
}

.admin .sidemenu .tags ul li {
    display: inline-block;
}

.admin .sidemenu .tags ul a {
    color: #3f8db5;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #e4eff5;
    display: inline-block;
    transition: 0.3s;
}

.admin .sidemenu .tags ul a:hover {
    color: #fff;
    border: 1px solid #32627b;
    background: #e75480;
}

.admin .sidemenu .tags ul a span {
    padding-left: 5px;
    color: #bedae8;
    font-size: 14px;
}
.admin table {
  width: 100%;
}

.admin .table-container {
  overflow-x: auto;
}
</style>
